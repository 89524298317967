import { ThemeConfig, theme } from "antd";

// Edited with: https://ant.design/theme-editor

const config: ThemeConfig = {
  token: {
    colorBgBase: "#363a40",
    colorTextBase: "#aeb8c4",
    fontFamily: '"Roboto", sans-serif',
    colorLink: "#ffffff"
  },
  components: {
    Anchor: {
      colorPrimary: "rgb(255, 255, 255)"
    },
    Form: {
      labelFontSize: 16,
      fontFamily: '"Roboto",sans-serif',
    },
    Tag: {
      colorPrimary: "rgb(1, 153, 226)",
      defaultBg: "rgb(61, 67, 76)",
      defaultColor: "rgb(255, 255, 255)",
      fontFamily: '"Ropa Sans", sans-serif;',
      fontSize: 24,
      algorithm: true
    },
    Pagination: {
      colorPrimary: "rgb(255, 255, 255)",
      colorPrimaryHover: "rgb(226, 223, 223)",
      fontSize: 20,
      fontFamily: '"Nunito Sans", sans-serif'
    },
    Input: {
      colorBgContainer: "rgb(76, 83, 93)",
      colorText: "rgb(255, 255, 255)",
      colorBorder: "rgba(76, 83, 93, 0)",
      colorTextPlaceholder: "rgba(174, 184, 196, 0.75)",
      algorithm: true,
      activeShadow: "none",
      inputFontSize: 32,
      paddingBlock: 6,
      paddingInline: 24,
    },
    Switch: {
      colorPrimary: "rgb(34, 222, 121)",
      colorPrimaryBorder: "rgb(112, 233, 168)",
      colorPrimaryHover: "rgb(34, 222, 121)",
      handleSize: 28,
      trackHeight: 34,
      innerMaxMargin: 36,
      innerMinMargin: 10,
      trackMinWidth: 55,
      trackPadding: 3
    },
    Typography: {
      fontSizeHeading5: 36,
      fontWeightStrong: 500,
    },
    Tabs: {
      itemSelectedColor: "rgb(174, 184, 196)",
      fontFamily: '"Hind", sans-serif',
      cardGutter: 0,
      borderRadius: 5,
      borderRadiusLG: 5,
      colorBorderSecondary: "rgba(40, 43, 47, 0)",
      colorBgContainer: "rgb(76, 83, 93)",
      inkBarColor: "rgb(174, 184, 196)",
      cardBg: "rgb(61, 68, 78)",
      cardPadding: "12px 12px",
      titleFontSize: 16,
      fontSize: 14,
      margin: 46,
    },
    Table: {
      borderColor: "rgb(132, 146, 161)",
      cellPaddingBlock: 12,
      colorBgContainer: "rgb(54, 58, 64)",
      borderRadius: 0,
      fontFamily: '"Nunito Sans", sans-serif',
      headerBorderRadius: 0,
      fontSize: 16,
      cellFontSize: 16,
    },
    Card: {
      fontFamily: '"Ropa Sans", sans-serif;',
      fontWeightStrong: 400,
      fontSize: 12,
      fontSizeLG: 12,
      padding: 6,
    },
  },
  algorithm: theme.darkAlgorithm,
};

export default config;
