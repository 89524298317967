import axiosHttp from "../utils/axios";

export interface LoginParameters {
  pin: string;
}

export const login = (password: string): Promise<any> => {
  
  return axiosHttp.post<LoginParameters>(`/login`,  { password });
};
