import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { FC } from "react";
interface LoaderProps {
  spinning: boolean;
}

const Loader: FC<LoaderProps> = ({ spinning }) => (
  <Spin
    fullscreen
    delay={200}
    spinning={spinning}
    indicator={<Loading3QuartersOutlined style={{ fontSize: "44px" }} spin />}
  />
);

export default Loader;
