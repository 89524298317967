import { useGlobalContext } from 'GlobalContex';
import { Form, Input, Typography, notification } from 'antd';
import Loader from 'components/Loader/Loader';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { jtwParser } from 'utils/jtwParser';
import { LoginParameters, login } from '../../services/auth';
import styles from './Login.module.scss';
const { Title } = Typography;
interface LoginProps {}

const Login: FC<LoginProps> = () => {
 const navigate = useNavigate();
 const {isLoading, setIsLoading, setUserName, setUserId} = useGlobalContext();
 const [api, contextHolder] = notification.useNotification();

  const handleLogin = ({pin}: LoginParameters): void => {
   setIsLoading(true);
    login(pin).then((response) => {
      const {username, userId} = jtwParser(response.data.token);
      localStorage.setItem('taco_username', username);
      localStorage.setItem('taco_user_id', userId);
      setUserName(username);
      setUserId(userId);
      navigate('/pages');
    }).catch((error) => {
     api.error({
      message: 'Warning!',
      description: error.message,
     });
    }).finally(() => setIsLoading(false));
  }
 return (<div className={styles.Login}>
  {contextHolder}

   <div className={styles.logo}><img alt="logo" src="logo.svg" /> </div>
   <div className={styles.form_container}>
    <div className={styles.form_input}>
     <Form 
     name="login"
     autoComplete="off"
     onFinish={handleLogin}>
      <Title level={5}>Insert Pin</Title>
      <Form.Item
       name="pin"
       rules={[{ required: true, message: 'Please input pin!' }]}
       >
       <Input  placeholder="* * * * *" type="password"/>
      </Form.Item>
      </Form>
    </div>
   </div>
   <Loader spinning={isLoading}/>
  </div>);

}

export default Login;
