import { Col, Row } from "antd";
import { EditIcon } from "assets/icons";
import cn from "classnames";
import IconButton from "components/IconButton/IconButton";
import { FC } from "react";
import { Product } from "../../types/Product";
import MovementsTable from "../MovementsTable/MovementsTable";
import ProgressBar from "../ProgressBar/ProgressBar";
import QuantityTag from "../QuantityTag/QuantityTag";
import { AlignedRight, TacoCard } from "../StyledComponents";
import styles from "./ProductCard.module.scss";
interface ProductCardProps {
  product: Product;
  action?: (_id: string) => void;
  showDetails?: boolean;
  stretch?: boolean;
}

const ProductCard: FC<ProductCardProps> = ({ product, action, showDetails = false, stretch = false }) => {
  const color = (!!product.category && product.category.color) || '';
  const title = (!!product.category && product.category.name) || '';
  return (
    <div className={cn(styles.ProductCard, (!!action && styles.clickable) || '', (stretch && styles.stretch) || '')}>
      <TacoCard className={cn(stretch ? styles.stretch : '')} $color={color} title={title} bordered={false} onClick={() => !!action && action(product._id)}>
        <Row align="middle">
          <Col span={16}>
            <h3>{product.name}</h3>
          </Col>
          <Col span={8}>
            {showDetails && <AlignedRight>
              <IconButton><EditIcon style={{ height: "30px" }} onClick={() => console.log("edit")} /></IconButton>
            </AlignedRight>}
          </Col>
        </Row>
        {showDetails && <div className={styles.supplier}>{product.supplier}</div>}
        {!showDetails && <div className={styles.tableContainer}>
          {product.movements && product.movements.length && <MovementsTable
            records={product.movements || []}
            showProductName={false}
            showDescription={false}
            small={true}
          /> || <span>Nessun movimento</span>}
        </div>}
        <Row align="middle">
          <Col span={16}>
            <ProgressBar
              alertQuota={product.alertQuota}
              quantity={product.quantity}
            />
          </Col>
          <Col span={8}>
            <AlignedRight >
              <QuantityTag quantity={product.quantity} />
            </AlignedRight>
          </Col>
        </Row>
        {showDetails && <Row align="middle">
          <Col span={8}>
            <div className={styles.details}>
              <div className={styles.detailsLabel}>Prezzo</div>
              <div className={styles.detailsContent}>{product.price} €</div>
            </div>
          </Col>
          <Col span={8}>
            <div className={styles.details}>
              <div className={styles.detailsLabel}>Iva</div>
              <div className={styles.detailsContent}>{product.vat || 'n.a.'} €</div>
            </div>
          </Col>
        </Row>}
      </TacoCard>
    </div>
  );
};

export default ProductCard;
