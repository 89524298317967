import { FC } from 'react';
import styles from './IconButton.module.scss';

const IconButton: FC<any> = (props) => (
  <div className={styles.IconButton}>
    {props.children}
  </div>
);

export default IconButton;
