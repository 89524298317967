import Loader from "components/Loader/Loader";
import Categories from "pages/Categories/Categories";
import CategoryDetails from "pages/CategoryDetails/CategoryDetails";
import Dashboard from "pages/Dashboard/Dashboard";
import Error from "pages/Error/Error";
import Layout from "pages/Layout/Layout";
import Login from "pages/Login/Login";
import Movements from "pages/Movements/Movements";
import NewCategory from "pages/NewCategory/NewCategory";
import NewProduct from "pages/NewProduct/NewProduct";
import ProductDetails from "pages/ProductDetails/ProductDetails";
import Scarcity from "pages/Scarcity/Scarcity";
import Search from "pages/Search/Search";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useGlobalContext } from "./GlobalContex";
import "./TacoApp.scss";

function TacoApp() {
  const { isLoading } = useGlobalContext();
 
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/pages" element={<Layout />}>
            <Route path="" element={<Dashboard />} />
            <Route path="movements" element={<Movements />} />
            <Route path="categories" element={<Categories />} />
            <Route path="categories/:id" element={<CategoryDetails />} />
            <Route path="categories/new" element={<NewCategory />} />
            <Route path="products/search" element={<Search />} />
            <Route path="products/new" element={<NewProduct />} />
            <Route path="products/:id" element={<ProductDetails />} />
            <Route path="scarcity" element={<Scarcity />} />
            <Route path="*" element={<Error />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <Loader spinning={!!isLoading} />
    </>
  );
}

export default TacoApp;
