import axios from "axios";
import { API_BASE_URL } from "../config";


const axiosHttp = axios.create({
  baseURL: `${API_BASE_URL}`,
});


axiosHttp.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem('taco_tkn');    
    return {
      ...config,
      headers: {
        ...(token !== null && { Authorization: `${token}` }),
        ...config.headers,
      },
    };
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosHttp.interceptors.response.use(
  (response) => {
    if(!!response.data && !!response.data.token) {
      localStorage.setItem('taco_tkn', response.data.token);
    }
    return response;
  },
  (error) => {
    if (!!error.response && !!error.response.status && [401, 403].includes(error.response.status)) {
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default axiosHttp;