import { useGlobalContext } from 'GlobalContex';
import { Col, Form, Input, Row, Tag, notification } from 'antd';
import { CloseIcon } from 'assets/icons';
import BigButton, { ButtonType } from 'components/BigButton/BigButton';
import FooterBar from 'components/FooterBar/FooterBar';
import IconButton from 'components/IconButton/IconButton';
import { AlignedRight, FormLabel, PageContainer, Title } from 'components/StyledComponents';
import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getCategories } from 'services/categories';
import { Category } from 'types/Category';
import { Product } from 'types/Product';
import styles from './ProductForm.module.scss';

interface ProductFormProps {
  product?: Product;
  onCancel: () => void
  onSave: (product: Product) => void
}

const ProductForm: FC<ProductFormProps> = ({product, onCancel, onSave}) => {
  const { setIsLoading } = useGlobalContext();

  const [categories, setCategories] = useState<Category[]>([]);
  const [api, contextHolder] = notification.useNotification();
  const [selectedCategory, setSelectedCategory] = useState<Category>();
  const [searchParams] = useSearchParams();
  const term = searchParams.get("term");
  const handleCategoryChange = (category: Category) => {
    setSelectedCategory(category);
  }
  
  const getCategoriesRequest = async () => {
    setIsLoading(true);
    await getCategories().then(response => {
      setCategories(response.data);
    })
    .catch((error) => {
      api.error({
        message: 'Warning!',
        description: error.message,
      });
    })
    .finally(() => setIsLoading(false));
    
  }

  const onSaveHandler = (product: any) => {
    onSave({...product, category: selectedCategory});
  }
  const priceRules = [
    { pattern: new RegExp(/^(?=.*\d)\d*(?:\.\d\d)?$/), message: "Per questo campo sono ammessi solo numeri es: 10 o 10.00"},
    () => ({
      validator(_: any, value: any) {
        if (!value) {
          return Promise.resolve();
        }
        if (parseFloat(value) < 0) {
          return Promise.reject('Il valore minimo per questo campo è 1');
        }
        return Promise.resolve();
      },
    })
  ];

  useEffect(() => {
    getCategoriesRequest();
    if(!!product) {
      setSelectedCategory(product.category);
    }
  }, []);

  return (
    <div className={styles.ProductForm}>
      {contextHolder}
      <PageContainer>
        <Row gutter={[8, 16]} align={'middle'} className={styles.topBlock}>
          <Col xs={16} md={20}>
            <Title $dark={true}>
              {(!!product && <span>MODIFICA<br/>PRODOTTO</span>) || <span>AGGIUNGI<br/>PRODOTTO</span>}
            </Title>
          </Col>
          <Col xs={8} md={4}>
            <AlignedRight >
              <IconButton >
                <CloseIcon style={{ height: "50px" }} onClick={onCancel} />
              </IconButton>
            </AlignedRight>
          </Col>
        </Row>

        <Form 
        name="login"
        autoComplete="off"
        onFinish={onSaveHandler}
        initialValues={(!!product && {...product}) || (!!term && {codebar: term, name: term} )|| {}}
        >
          <Row gutter={[30, 30]}>
            <Col span={24}>
              <FormLabel>Barcode</FormLabel>
              <Form.Item name="codebar" rules={[{ required: true, message: 'Il campo BARCODE è obbligatorio !' }]}>
                <Input type="string" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
                <FormLabel>Nome Prodotto</FormLabel>
                <Form.Item name="name" rules={[{ required: true, message: 'Il campo NOME PRODOTTO è obbligatorio !' }]}>
                  <Input type="string"/>
                </Form.Item>
                <FormLabel>Fornitore</FormLabel>
                <Form.Item name="supplier" rules={[{ required: true, message: 'Il campo FORNITORE è obbligatorio !' }]}>
                  <Input type="string"/>
                </Form.Item>
                
                <Row gutter={[30, 30]}>
                  <Col xs={24} md={12}>
                    <FormLabel>Prezzo</FormLabel>
                    <Form.Item name="price" rules={[...priceRules]}>
                      <Input type="number"/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <FormLabel>IVA</FormLabel>
                    <Form.Item name="vat" rules={[...priceRules]}>
                      <Input type="number"/>
                    </Form.Item>
                  </Col>
                </Row>
              
                <FormLabel>Soglia Minima</FormLabel>
                <Form.Item name="alertQuota" rules={[{ required: true, message: 'Il campo SOGLIA MINIMA è obbligatorio !' }]}>
                  <Input type="number"/>
                </Form.Item>
              </Col>  
              <Col xs={24} md={12}>
                <FormLabel>Tipo</FormLabel>
                <div className={styles.productCategories}>
                  {categories.map(category => <Tag.CheckableTag
                                                key={category._id}
                                                checked={(selectedCategory && selectedCategory._id === category._id) || false}
                                                onChange={() => handleCategoryChange(category)}
                                              >
                                                {category.name.toUpperCase()}
                                              </Tag.CheckableTag>)
                  }
                </div>
              </Col>  
              
          </Row>
          <FooterBar>
            <BigButton
                label="annulla"
                fullSize
                style={"silverChalice" as ButtonType}
                onClick={(e) => {
                  e.preventDefault();
                  onCancel();
                }}
              />
            <BigButton
                type="submit"
                label={'salva'}
                fullSize
                style={('tuftsBlue') as ButtonType}
              />
          </FooterBar>
        </Form>


      </PageContainer>
    </div>
  );
};

export default ProductForm;
