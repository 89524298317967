import { FC, ReactNode } from 'react';
import styles from './RowContainer.module.scss';

interface RowContainerProps {
  categoryColor: string;
  children: ReactNode;
}

const RowContainer: FC<RowContainerProps> = ({categoryColor, children}) => (
  <div className={styles.RowContainer}>
    <div className={styles.category} style={{'backgroundColor': categoryColor}}></div>
    {children}
  </div>
);

export default RowContainer;
