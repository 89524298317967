import { useGlobalContext } from 'GlobalContex';
import { notification } from 'antd';
import ProductForm from 'components/ProductForm/ProductForm';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { insertProduct } from 'services/products';
import { Product } from 'types/Product';
import styles from './NewProduct.module.scss';

interface ProductProps {}

const NewProduct: FC<ProductProps> = () => {
  const navigate = useNavigate();
  const location= useLocation();
  const { setIsLoading } = useGlobalContext();
  const [api, contextHolder] = notification.useNotification();

  const saveProductHandler = async (product: Product) => {
    setIsLoading(true);
    await insertProduct(product)
    .then((result) => {
      navigate(`/pages/products/${result.data._id}`);
    })
    .catch((error) => {
      api.error({
        message: 'Warning!',
        description: error.message,
      });
    })
    .finally(() => setIsLoading(false));

  };

  const onCancel = () => {
      let prevPath: string  = '/pages';
                if(location.state && location.state.prevPath) {
                  prevPath =  (location.state.prevPath as string[]).pop() || '/pages';
                } 
                navigate(prevPath, {state : { prevPath: (location.state && [...location.state.prevPath]) || ['/pages'] }});
    };


  
  return (
  <div className={styles.NewProduct}>
    {contextHolder}
    <ProductForm onCancel={onCancel} onSave={saveProductHandler} />
  </div>
);
};

export default NewProduct;
