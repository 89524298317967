import Icon from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import cn from 'classnames';
import BigButton, { ButtonType } from "components/BigButton/BigButton";
import FooterBar from "components/FooterBar/FooterBar";
import InfoBlock from "components/InfoBlock/InfoBlock";
import ProductCard from "components/ProductCard/ProductCard";
import ScarcityRow from "components/ScarcityRow/ScarcityRow";
import {
  ImportantText,
  PageContainer,
  SectionTitle,
  Title
} from "components/StyledComponents";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../GlobalContex";
import { DropIcon, GridViewIcon, ListViewIcon, MovementsIcon, PlusIcon } from "../../assets/icons";
import { getDashboardInfo, getInventoryValue, getProducts } from "../../services/products";
import { Product } from "../../types/Product";
import styles from "./Dashboard.module.scss";

interface DashboardProps {}

const Dashboard: FC<DashboardProps> = () => {
  const navigate = useNavigate();
  const { setIsLoading } = useGlobalContext();
  const [totalValue, setTotalValue] = useState<number>(0);
  const location = useLocation();
  // const [lastMovements, setLastMovements] = useState<Movement[]>([]);
  // const [lastScarcityProducts, setLastScarcityProducts] = useState<Product[]>([]);
  const [gridView, setGridView] = useState<boolean>(false);
  const [movements, setMovements] = useState<number>(0);
  const [scarcityProducts, setScarcityProducts] = useState<number>(0);
  const [products, setProducts] = useState<Product[]>([]);

  const getInventoryValueHandler = async (): Promise<void> => {
    setIsLoading(true);
    await getInventoryValue().then((response) => {
      if(response.data) {
        setTotalValue(response.data.total);
      }
    }).catch((error) => {})
    .finally(() => setIsLoading(false));
  }
  const getDashboardInfoHandler = async (): Promise<void> => {
    setIsLoading(true);
    await getDashboardInfo().then((response) => {
      if(response.data) {
        setScarcityProducts(response.data.scarcity);
        setMovements(response.data.movements);
      }
    }).catch((error) => {})
    .finally(() => setIsLoading(false));
  }
  const getProductsHandler = async (
    page = 1,
    limits = 18,
    filters?: any
  ): Promise<void> => {
    setIsLoading(true);
    await getProducts(page, limits, filters)
      .then((result) => {
        setProducts(result.data);
      })
      .catch((error) => {})
      .finally(() => setIsLoading(false));
  };

  // const getLastMovements = async (): Promise<void> => {
  //   setIsLoading(true);
  //   await getMovements(1, 5)
  //     .then((result) => {
  //       setLastMovements(result.data.records);
  //     })
  //     .catch((error) => {})
  //     .finally(() => setIsLoading(false));
  // };

  // const getLastScarcity = async (): Promise<void> => {
  //   setIsLoading(true);
  //   await getScarcity(1, 5)
  //     .then((result) => {
  //       setLastScarcityProducts(result.data);
  //     })
  //     .catch((error) => {})
  //     .finally(() => setIsLoading(false));
  // };

  // const tabs = useMemo(
  //   () => [
  //     {
  //       label: (
  //         <div>
  //           ULTIMI
  //           <br />
  //           MOVIMENTI
  //         </div>
  //       ),
  //       key: "1",
  //       children: (
  //         <>
  //           <MovementsTable records={lastMovements} />
  //           <div className={styles.tableCta} onClick={() => navigate('/pages/movements')}>
  //             <span>ALTRI PRODOTTI</span>{" "}
  //             <ChevronIcon style={{ height: "30px" }} />
  //           </div>
  //         </>
  //       ),
  //     },
  //     {
  //       label: (
  //         <div>
  //           GIACENZE
  //           <br />
  //           IN ESAURIMENTO
  //         </div>
  //       ),
  //       key: "2",
  //       children: (
  //         <>
  //           <ScarcityTable records={lastScarcityProducts} />
  //           <div className={styles.tableCta} onClick={() => navigate('/pages/scarcity')}>
  //             <span>ALTRI PRODOTTI</span>{" "}
  //             <ChevronIcon style={{ height: "30px" }} />
  //           </div>
  //         </>
  //       ),
  //     },
  //   ],
  //   [lastMovements, lastScarcityProducts]
  // );

  const goToProductDetails = (_id: string) => navigate(`products/${_id}`, {relative: "route", state : { prevPath: [location.pathname] }});

  const switchToView = (view: 'grid' | 'list'): void => {
    setGridView(view === 'grid');
  }
  useEffect(() => {
    getProductsHandler();
    // getLastMovements();
    // getLastScarcity();
    getDashboardInfoHandler();
    getInventoryValueHandler();
  }, []);

  return (
      <div className={styles.Dashboard}>
        <PageContainer>
          <Row gutter={[8, 16]} className={styles.topBlock}>
            <Col md={8} xs={24} >
              <Title $dark={true}>
                VALORE
                <br />
                MAGAZZINO
              </Title>
              <ImportantText>{totalValue} €</ImportantText>
            </Col>
            <Col md={16} xs={24}>
              {/* <TacoTabs type="card" items={tabs} /> */}
              <div className={styles.infoBlockContainer}>
                <InfoBlock onClick={() => navigate('/pages/scarcity')} red={true} icon={<DropIcon style={{ height: "30px" }}/>} subtitle='in esaurimento' total={scarcityProducts}/>
                <InfoBlock onClick={() => navigate('/pages/movements')} icon={<MovementsIcon style={{ height: "30px" }} />} subtitle='ULTIMI MOVIMENTI' total={movements}/>
              </div>
            </Col>
          </Row>
          <Row gutter={[20, 20]} align={'bottom'}>
            <Col xs={12}>
              <SectionTitle >
                LISTA
                <br />
                PRODOTTI
              </SectionTitle>
            </Col>
            <Col xs={12}>
              <div className={styles.switchContainer}>
                <Button type="link" icon={<GridViewIcon className={cn(gridView ? styles.highLight : '')}/>} onClick={() => switchToView('grid')}></Button>
                <div className={styles.divider}></div>
                <Button type="link" icon={<ListViewIcon className={cn(!gridView ? styles.highLight : '')}/>}  onClick={() => switchToView('list')}></Button>
              </div>
            </Col>
          </Row>
         {gridView && (<Row gutter={[20, 20]}>
            {products.map((product) => (
              <Col xl={8} md={12} xs={24} key={product._id}>
                  <ProductCard stretch product={product} action={goToProductDetails} />
                </Col>
              ))
            }</Row>)}

          {!gridView && (<div className={styles.listContainer}> {products.map((product) => (<ScarcityRow  key={product._id} onClick={() => {console.log('click', product._id); goToProductDetails(product._id)}} showLastMovement product={product}/>))}</div>)}
          
        </PageContainer>

        <FooterBar>
          <BigButton
            label="in esaurimento"
            icon={<Icon component={() => <DropIcon style={{ height: "30px" }} />} />}
            style={"silverChalice" as ButtonType}
            onClick={() => navigate('/pages/scarcity')}
          />
          <BigButton
            label="aggiungi prodotto"
            icon={
              <Icon
                component={() => <PlusIcon style={{ height: "30px" }} />}
                height={30}
              />
            }
            style={"silverChalice" as ButtonType}
            onClick={() => navigate('products/new')}
          />
          <BigButton
            label="movimenti"
            icon={
              <Icon
                component={() => <MovementsIcon style={{ height: "30px" }} />}
                height={30}
              />
            }
            style={"silverChalice" as ButtonType}
            onClick={() => navigate('/pages/movements')}
          />
        </FooterBar>
      </div>
  );
};

export default Dashboard;
