import { FC } from "react";
import styles from "./FooterBar.module.scss";

const FooterBar: FC<any> = (props) => (
  <div className={styles.FooterBar}>
    {props.children}
  </div>
);

export default FooterBar;
