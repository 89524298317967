import { useGlobalContext } from 'GlobalContex';
import { notification } from 'antd';
import CategoryForm from 'components/CategoryForm/CategoryForm';
import ScarcityRow from 'components/ScarcityRow/ScarcityRow';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getCategoryById, updateCategoryById } from 'services/categories';
import { getProductsByCategoryById } from 'services/products';
import { Category } from 'types/Category';
import { Product } from 'types/Product';
import styles from './CategoryDetails.module.scss';

interface CategoryDetailsProps {}

const CategoryDetails: FC<CategoryDetailsProps> = () => { 

  const { id } = useParams();
  const navigate = useNavigate();
  const { setIsLoading } = useGlobalContext();
  const [category, setCategory] = useState<Category>();
  const [products, setProducts] = useState<Product[]>();

   const [api, contextHolder] = notification.useNotification();
  const location= useLocation();
  const getCategory = async (id: string) => {
    setIsLoading(true);
    await getCategoryById(id)
    .then((result) => {
      setCategory(result.data);
    })
    .catch((error) => {
      api.error({
        message: 'Warning!',
        description: error.message,
      });
    })
    .finally(() => setIsLoading(false));

  };

  const editCategoryHandler = async (category: Category, reload = true) => {
    if(!id) {
      return;
    }
    setIsLoading(true);
    await updateCategoryById(id, category)
    .then((result) => {
      reload && window.location.reload();
    })
    .catch((error) => {
      api.error({
        message: 'Warning!',
        description: error.message,
      });
    })
    .finally(() => setIsLoading(false));

  };

  const getProductsByCategoryByIdHandler = async (id: string) => {
    if(!id) {
      return;
    }
    setIsLoading(true);
    await getProductsByCategoryById(id)
    .then((result) => {
      console.log('getProductsByCategoryById', result);
      !!result.data && setProducts(result.data);
    })
    .catch((error) => {
      api.error({
        message: 'Warning!',
        description: error.message,
      });
    })
    .finally(() => setIsLoading(false));

  };

  useEffect(() => {
    !!id && getCategory(id);
    !!id && getProductsByCategoryByIdHandler(id);
  }, [id]);


  return (
  <div className={styles.CategoryDetails}>
    {contextHolder}
    {category && <CategoryForm category={category} onCancel={() => {
      let prevPath: string  = '/pages';
      if(location.state && location.state.prevPath) {
        prevPath =  (location.state.prevPath as string[]).pop() || '/pages';
      } 
      navigate(prevPath, {state : { prevPath: (location.state && [...location.state.prevPath]) || ['/pages'] }});
    }} onSave={editCategoryHandler} > 
    <>
    <div className={styles.productsHeader}>
          <div className={styles.productsHeader_cell}>PRODOTTO</div>
          <div className={styles.productsHeader_cell}></div>
          <div className={styles.productsHeader_cell}>Quantità</div>
        </div>
    <div className={styles.productsContainer}>
          {products && products.map((product) => <ScarcityRow onClick={() => {

            navigate(`/pages/products/${product._id}`, {state : { prevPath: (location.state && [...location.state.prevPath,  location.pathname]) || [location.pathname] }})
          }} key={product._id} product={product} />)}
        </div></>
    </CategoryForm>}

    
  </div>
);
};

export default CategoryDetails;
