import { App, ConfigProvider } from "antd";
import ReactDOM from "react-dom/client";
import { GlobalContextProvider } from "./GlobalContex";
import TacoApp from "./TacoApp";
import "./index.scss";
import config from "./styles/theme";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ConfigProvider theme={config}>
    <App>
      <GlobalContextProvider>
        <TacoApp />
      </GlobalContextProvider>
    </App>
  </ConfigProvider>
);
