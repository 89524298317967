import { useGlobalContext } from 'GlobalContex';
import { notification } from 'antd';
import CategoryForm from 'components/CategoryForm/CategoryForm';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { insertCategory } from 'services/categories';
import { Category } from 'types/Category';
import styles from './NewCategory.module.scss';

interface NewCategoryProps {}

const NewCategory: FC<NewCategoryProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setIsLoading } = useGlobalContext();
  const [api, contextHolder] = notification.useNotification();

  const saveCategoryHandler = async (product: Category) => {
    setIsLoading(true);
    await insertCategory(product)
    .then((result) => {
      navigate(`/pages/categories/${result.data._id}`, {state : { prevPath: [...location.state.prevPath] }});
    })
    .catch((error) => {
      api.error({
        message: 'Warning!',
        description: error.message,
      });
    })
    .finally(() => setIsLoading(false));

  };
  
  return (
  <div className={styles.NewCategory}>
    {contextHolder}
    <CategoryForm onCancel={() => {
        let prevPath: string  = '/pages';
        if(location.state && location.state.prevPath) {
          prevPath =  (location.state.prevPath as string[]).pop() || '/pages';
        } 
        navigate(prevPath, {state : { prevPath: (location.state && [...location.state.prevPath]) || ['/pages'] }});
      }} onSave={saveCategoryHandler} />
  </div>
);
};

export default NewCategory;
