import cn from 'classnames';
import { FC, ReactElement } from 'react';
import styles from './InfoBlock.module.scss';
interface InfoBlockProps {
  red?: boolean;
  total: number;
  subtitle: string;
  icon: ReactElement
  onClick?: () => void;
}

const InfoBlock: FC<InfoBlockProps> = ({total, subtitle, icon, onClick, red = false}) => {

  return (
    <div className={cn(styles.InfoBlock, red ? styles.red : ''  )} onClick={() => !!onClick && onClick()}>
      <div className={styles.stats}>{icon} {total}</div>
      <div>{subtitle}</div>
    </div>
  );
};

export default InfoBlock;
