import { Button } from "antd";
import { BarcodeIcon } from "assets/icons";
import TopBar from "components/TopBar/TopBar";
import { FC } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styles from "./Layout.module.scss";

interface LayoutProps {}

const Layout: FC<LayoutProps> = () => {

  const navigate = useNavigate();
  const location = useLocation();
  
  return (
    <>
      <div className={styles.Layout}>
        <TopBar sticky>
          <TopBar.Left>Filtri</TopBar.Left>
          <TopBar.Right>
            <Button type="link" onClick={() => {
                  navigate(`/pages/categories`, {state : { prevPath: (location.state && [...location.state.prevPath,  location.pathname]) || [location.pathname] }})
                }}>Categorie</Button>
            <Button type="link" onClick={() => navigate('/pages/products/search', {state : { prevPath: (location.state && [...location.state.prevPath,  location.pathname]) || [location.pathname] }})}><BarcodeIcon style={{ height: "24px" }}/></Button>
          </TopBar.Right>
        </TopBar>
        <Outlet />
      </div>
    </>
  );
};

export default Layout;
