import { Progress } from "antd";
import { FC } from "react";
import styles from "./ProgressBar.module.scss";

interface ProgressBarProps {
  alertQuota: number;
  quantity: number;
}

const ProgressBar: FC<ProgressBarProps> = ({
  alertQuota,
  quantity,
}) => {
  const percentage = (quantity && Math.round((quantity / alertQuota) * 20)) || 0;
  const color = percentage < alertQuota ? "#FB535D" : "#22DE79";
  return (
    <div className={styles.ProgressBar}>
      <Progress
        type="line"
        showInfo={false}
        percent={percentage < 100 ? percentage : 100}
        strokeColor={color}
      />
    </div>
  );
};

export default ProgressBar;
