import { Col, ColorPicker, Form, Input, Row } from 'antd';
import { CloseIcon } from 'assets/icons';
import BigButton, { ButtonType } from 'components/BigButton/BigButton';
import FooterBar from 'components/FooterBar/FooterBar';
import IconButton from 'components/IconButton/IconButton';
import { AlignedRight, FormLabel, PageContainer, Title } from 'components/StyledComponents';
import { FC, ReactElement } from 'react';
import { Category } from 'types/Category';
import styles from './CategoryForm.module.scss';

interface CategoryFormProps {
  category?: Category;
  onCancel: () => void;
  onSave: (category: Category) => void;
  children?: ReactElement
}

const CategoryForm: FC<CategoryFormProps> = ({category, onCancel, onSave, children}) => {

  const onSaveHandler = (category: any) => {
    let color = typeof category.color === 'object' ? category.color.toHex() : category.color;
    onSave({...category, color: `${color[0] === '#' ? '' : '#'}${color}`});
  }

  return (
    <div className={styles.CategoryForm}>
      <PageContainer>
        <Row gutter={[8, 16]} align={'middle'} className={styles.topBlock}>
          <Col md={20}>
            <Title $dark={true}>
              {(!!category && <span>MODIFICA<br/>Categoria</span>) || <span>AGGIUNGI<br/>Categoria</span>}
            </Title>
          </Col>
          <Col md={4}>
            <AlignedRight >
              <IconButton >
                <CloseIcon style={{ height: "50px" }} onClick={onCancel} />
              </IconButton>
            </AlignedRight>
          </Col>
        </Row>

        <Form 
        name="login"
        autoComplete="off"
        onFinish={onSaveHandler}
        initialValues={(!!category && {...category}) || {} }
        >
          <Row gutter={[30, 30]}>
            <Col xs={24} md={12}>
                <FormLabel>Nome Categoria</FormLabel>
                <Form.Item name="name" rules={[{ required: true, message: 'Il campo NOME CATEGORIA è obbligatorio !' }]}>
                  <Input type="string"/>
                </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              
                <FormLabel>Colore</FormLabel>
                <Form.Item name="color" rules={[{ required: true, message: 'Il campo COLORE è obbligatorio !' }]}>
                  <ColorPicker size="large" showText format="hex" onChange={(color, hex) => {

                  }} />
                </Form.Item>
              </Col>  
          </Row>
          {children}
          <FooterBar>
            <BigButton
                label="annulla"
                fullSize
                style={"silverChalice" as ButtonType}
                onClick={(e) => {
                  e.preventDefault();
                  onCancel();
                }}
              />
            <BigButton
                type="submit"
                label={'salva'}
                fullSize
                style={('tuftsBlue') as ButtonType}
              />
          </FooterBar>
        </Form>


      </PageContainer>
    </div>
  );
};

export default CategoryForm;
