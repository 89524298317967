import { FC } from "react";
import styles from "./QuantityTag.module.scss";

interface QuantityTagProps {
  quantity: number;
}

const QuantityTag: FC<QuantityTagProps> = ({ quantity = 0 }) => (
  <div className={styles.QuantityTag}>
    <span>QTÀ</span> {<strong>{quantity}</strong>}
  </div>
);

export default QuantityTag;
