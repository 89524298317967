import cn from 'classnames';
import MovementTag from 'components/MovementTag/MovementTag';
import RowContainer from 'components/RowContainer/RowContainer';
import { format } from 'date-fns';
import { FC } from 'react';
import { Movement } from 'types/Movement';
import styles from './MovementRow.module.scss';

interface MovementRowProps extends React.ButtonHTMLAttributes<HTMLDivElement> {
  movement: Movement;
}

const MovementRow: FC<MovementRowProps> = ({movement, onClick}) => (
  <div className={styles.MovementRow} onClick={onClick}>
    <RowContainer categoryColor={movement?.category?.color || ''}>
      <div className={cn(styles.productName, styles.cell)}>{movement.product?.name || 'sconosciuto'}</div>
      <div className={cn(styles.createdAt, styles.cell)}>
        {movement.createdAt
            ? format(movement.createdAt, "dd/MM/yyyy hh:mm")
            : ""}
      </div>
      <div className={cn(styles.description, styles.cell)}>
        {movement.description}
      </div>
      <div className={cn(styles.quantity, styles.cell)}>
        <MovementTag quantity={movement.quantity} type={movement.type}  />
      </div>
    </RowContainer>
  </div>
);

export default MovementRow;