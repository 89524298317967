import cn from "classnames";
import { FC } from "react";
import styles from "./MovementTag.module.scss";
interface MovementTagProps {
  quantity: number;
  type: 'LOAD' | 'UNLOAD';
}

const MovementTag: FC<MovementTagProps> = ({ quantity, type }) => (
  <div className={cn(styles.MovementTag, type === 'LOAD' ? styles.plus : styles.minus)}>
    {type === 'LOAD' ? "+" : '-'}
    {quantity}
  </div>
);

export default MovementTag;
