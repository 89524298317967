import { useGlobalContext } from 'GlobalContex';
import { Col, Row, notification } from 'antd';
import { CloseIcon } from 'assets/icons';
import IconButton from 'components/IconButton/IconButton';
import ScarcityRow from 'components/ScarcityRow/ScarcityRow';
import { AlignedRight, PageContainer, Title } from 'components/StyledComponents';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getScarcity } from 'services/products';
import { Product } from 'types/Product';
import styles from './Scarcity.module.scss';

interface ScarcityProps {}

const Scarcity: FC<ScarcityProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [api, contextHolder] = notification.useNotification();
  const { setIsLoading } = useGlobalContext();
  const [ products, setProducts ] = useState<Product[]>([]);

  const getScarcityHandler = async () => {
    setIsLoading(true);
    await getScarcity(1, 2000)
    .then((result) => {
      setProducts(result.data);
    })
    .catch((error) => {
      api.error({
        message: 'Warning!',
        description: error.message,
      });
    })
    .finally(() => setIsLoading(false));

  };

  useEffect(() => {
    getScarcityHandler();
  }, []);
  return (
    <div className={styles.Scarcity}>
      {contextHolder}
      <PageContainer>
        <Row gutter={[8, 16]} className={styles.topBlock}>
            <Col span={20}>
              <Title $dark={true}>
                In
                <br />
                Esaurimento
              </Title>
            </Col>
            <Col span={4}>
              <AlignedRight >
                <IconButton >
                  <CloseIcon style={{ height: "50px" }} onClick={() => navigate('/pages')} />
                </IconButton>
              </AlignedRight>
            </Col>
        </Row>

        <div className={styles.productsHeader}>
          <div className={styles.productsHeader_cell}>PRODOTTO</div>
          <div className={styles.productsHeader_cell}></div>
          <div className={styles.productsHeader_cell}>Quantità</div>
        </div>
        <div className={styles.productsContainer}>
          {products && products.map((product) => <ScarcityRow onClick={() => {

            navigate(`/pages/products/${product._id}`, {state : { prevPath: (location.state && [...location.state.prevPath,  location.pathname]) || [location.pathname] }})
          }} key={product._id} product={product} />)}
        </div>
      </PageContainer>
    </div>
  );
}

export default Scarcity;
