import cn from "classnames";
import { FC, ReactNode } from "react";
import styles from "./BigButton.module.scss";
export type ButtonType =
  | "darkBlueGray"
  | "mediumTurquoise"
  | "silverChalice"
  | "sunsetOrange"
  | "tuftsBlue"
  | "ufoGreen";
interface BigButtonProps {
  label: string;
  style: ButtonType;
  icon?: ReactNode | null;
  fullSize?: boolean;
  type?: string;
  onClick?: (e:any) => void;
}

const BigButton: FC<BigButtonProps & React.ComponentProps<"button">> = ({
  label,
  style,
  onClick,
  icon = null,
  fullSize = false,
  disabled = false,
  type
}) => {
  return (
    <button
      type={type || 'button'}
      onClick={onClick}
      disabled={disabled}
      className={cn(styles.BigButton, styles[style], fullSize ? styles.fullSize : '')}
    >
      {icon}
      <span>{label}</span>
    </button>
  );
};

export default BigButton;
