import { useGlobalContext } from 'GlobalContex';
import { Col, Row, Switch, notification } from 'antd';
import { CloseIcon } from 'assets/icons';
import BigButton, { ButtonType } from 'components/BigButton/BigButton';
import FooterBar from 'components/FooterBar/FooterBar';
import IconButton from 'components/IconButton/IconButton';
import MovementsTable from 'components/MovementsTable/MovementsTable';
import ProductCard from 'components/ProductCard/ProductCard';
import ProductForm from 'components/ProductForm/ProductForm';
import QuantityForm from 'components/QuantityForm/QuantityForm';
import { AlignedRight, PageContainer, SectionTitle, Title } from 'components/StyledComponents';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getMovementsByProductId, getProductById, setMovementByProductId, updateProductById } from 'services/products';
import { Movement, MovementTypes } from 'types/Movement';
import { Product } from 'types/Product';
import styles from './ProductDetails.module.scss';

interface ProductProps {}

const ProductDetails: FC<ProductProps> = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { setIsLoading } = useGlobalContext();
  const [ editQuantityType, setEditQuantityType ] = useState<MovementTypes | null>(null);
  const [ editProduct, setEditProduct ] = useState<boolean>(false);
  const [product, setProduct] = useState<Product>();
  const [movements, setMovements] = useState<Movement[]>([]);
  const [api, contextHolder] = notification.useNotification();
  const location= useLocation();

  const getProduct = async (id: string) => {
    setIsLoading(true);
    await getProductById(id)
    .then((result) => {
      setProduct(result.data);
    })
    .catch((error) => {
      api.error({
        message: 'Warning!',
        description: error.message,
      });
    })
    .finally(() => setIsLoading(false));

  };
  const getAllMovements = async (id: string) => {
    setIsLoading(true);
    await getMovementsByProductId(id)
    .then((result) => {
      setMovements(result.data);
    })
    .catch((error) => {
      api.error({
        message: 'Warning!',
        description: error.message,
      });
    })
    .finally(() => setIsLoading(false));

  };

  const editQuantityHandler = async ( quantity: number, type: MovementTypes, description?: string) => {
    setIsLoading(true);
    await setMovementByProductId(id as string, quantity, type, description)
    .then((result) => {
      setEditQuantityType(null);
      getProduct(id as string);
      getAllMovements(id as string);
    })
    .catch((error) => {
      api.error({
        message: 'Warning!',
        description: error.message,
      });
    })
    .finally(() => setIsLoading(false));

  };

  const activateProductHandler = async (active: boolean) => {
    setProduct({...product, active} as Product);
    editProductHandler({...product, active} as Product, false);
  };

  const editProductHandler = async (product: Product, reload = true) => {
    if(!id) {
      return;
    }
    setIsLoading(true);
    await updateProductById(id, product)
    .then((result) => {
      reload && window.location.reload();
    })
    .catch((error) => {
      api.error({
        message: 'Warning!',
        description: error.message,
      });
    })
    .finally(() => setIsLoading(false));

  };




  useEffect(() => {
    !!id && getProduct(id);
    !!id && getAllMovements(id);
  }, [id]);
  
  return (
  <div className={styles.ProductDetails}>
    {contextHolder}
    {!editProduct && id && product && <><PageContainer>
      <Row gutter={[8, 16]} align={'middle'} className={styles.topBlock}>
        <Col xs={16} md={20}>
          <Title $dark={true}>
            SCHEDA
            <br />
            PRODOTTO
          </Title>
        </Col>
        <Col xs={8} md={4}>
          <AlignedRight >
            <IconButton >
              <CloseIcon style={{ height: "50px" }} onClick={() => {
                let prevPath: string  = '/pages';
                if(location.state && location.state.prevPath) {
                  prevPath =  (location.state.prevPath as string[]).pop() || '/pages';
                } 
                navigate(prevPath, {state : { prevPath: (location.state && [...location.state.prevPath]) || ['/pages'] }});
              }} />
            </IconButton>
          </AlignedRight>
        </Col>
        
      </Row>
      
      <Row gutter={[20, 20]} >
        <Col xs={24} xl={12}>
          <SectionTitle>
            PRODOTTO
          </SectionTitle>
          <ProductCard product={product} showDetails action={() => setEditProduct(true)} />
          <div className={styles.switchHolder}>
            <span>ATTIVO ?</span> <Switch checkedChildren="Sì" unCheckedChildren="No" checked={product.active} onChange={(active) => activateProductHandler(active)} />
          </div>
        </Col>
        <Col xs={24} xl={12}>
          <SectionTitle>
            Movimenti
          </SectionTitle>
            <MovementsTable showDescription records={movements}/>
        </Col>
      </Row>

      <FooterBar>
        <BigButton
          label="carica +"
          fullSize
          style={"ufoGreen" as ButtonType}
          onClick={() => setEditQuantityType(MovementTypes.LOAD)}
          />
        <BigButton
          label="scarico -"
          fullSize
          style={"sunsetOrange" as ButtonType}
          onClick={() => setEditQuantityType(MovementTypes.UNLOAD)}
          />
      </FooterBar>
    
      {editQuantityType && <QuantityForm 
        type={editQuantityType} 
        onGo={editQuantityHandler}
        onCancel={() => {setEditQuantityType(null)}}
        ></QuantityForm>}
    </PageContainer></>}
    {product && editProduct && <ProductForm product={product} onCancel={() => setEditProduct(false)} onSave={editProductHandler} />}
  </div>
);
};

export default ProductDetails;
