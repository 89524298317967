import cn from 'classnames';
import MovementTag from 'components/MovementTag/MovementTag';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import RowContainer from 'components/RowContainer/RowContainer';
import { format } from 'date-fns';
import { FC } from 'react';
import { Product } from 'types/Product';
import styles from './ScarcityRow.module.scss';
interface ScarcityRowProps extends React.ButtonHTMLAttributes<HTMLDivElement> {
  product: Product;
  showLastMovement?: boolean;
}

const ScarcityRow: FC<ScarcityRowProps> = ({product, showLastMovement = false, onClick}) => (
  <div className={styles.ScarcityRow} onClick={onClick}>
    {/* <div className={styles.category} style={{'backgroundColor': product.category.color}}></div> */}
    <RowContainer categoryColor={product.category.color}>
      <div className={cn(styles.productName, styles.cell)}>{product.name}</div>
      <div className={cn(styles.progressBar, styles.cell)}>
        <ProgressBar quantity={product.quantity} alertQuota={product.alertQuota}/>
      </div>
      <div className={cn(styles.quantity, styles.cell)}>
        {product.quantity}
      </div>
      {showLastMovement && (<>
      <div className={cn(styles.createdAt, styles.cell)}>
        {!!product.movements && product.movements[0] && product.movements[0].createdAt
            ? format(product.movements[0].createdAt, "dd/MM/yyyy hh:mm")
            : ""}
      </div>
      <div className={cn(styles.quantity, styles.cell)}>
        {!!product.movements && product.movements[0] && <MovementTag quantity={product.movements[0].quantity} type={product.movements[0].type}  /> || ''}
      </div>
      </>)}
    </RowContainer>
  </div>
);

export default ScarcityRow;
