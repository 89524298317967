import cn from "classnames";
import { FC } from "react";
import styles from "./TopBar.module.scss";

interface TopBarProps {
  Left: any;
  Right: any;
}

const TopBar: FC<any> & TopBarProps = ({ sticky, children }) => (
  <div className={cn(styles.TopBar, (sticky && styles.sticky) || "")}>
    {children}
  </div>
);

const LeftPanel: FC<any> = (props) => (
  <div className={styles.leftPanel}>{props.children}</div>
);

const RightPanel: FC<any> = (props) => (
  <div className={styles.rightPanel}>{props.children}</div>
);

TopBar.Left = LeftPanel;
TopBar.Right = RightPanel;

export default TopBar;
