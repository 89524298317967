import { AxiosRequestConfig, AxiosResponse } from "axios";
import { Movement, MovementTypes } from "types/Movement";
import { Product } from "types/Product";
import axiosHttp from "../utils/axios";

export const getInventoryValue = (): Promise<any> => {
  return axiosHttp.get(`/value`);
};
export const getDashboardInfo = (): Promise<any> => {
  return axiosHttp.get(`/stats`);
};

export const updateProductById = (id: string, product: Product): Promise<any> => {
  return axiosHttp.put(`/products/${id}`, {...product});
};

export const insertProduct = (product: Product): Promise<any> => {
  return axiosHttp.post(`/products`, {...product});
};

export const getProducts = (
  page = 1,
  limit = 18,
  filters?: any
): Promise<any> => {
  const params: AxiosRequestConfig = {
    params: { filters: filters || [], page, limit },
  };
  return axiosHttp.get(`/products`, params);
};

export const searchProducts = (string: string): Promise<any> => {
  const params: AxiosRequestConfig = {
    params: {string} ,
  };
  return axiosHttp.get(`/products/search`, params);
};

export const getProductById = (_id: string): Promise<any> => {
  return axiosHttp.get(`/products/${_id}`);
};

export const getProductsByCategoryById = (_id: string): Promise<any> => {
  return axiosHttp.get(`/categories/${_id}/products`);
};

export const getScarcity = (
  page = 1,
  limit = 18,
  filters?: any
): Promise<any> => {
  const params: AxiosRequestConfig = {
    params: { filters: filters || [], page, limit },
  };
  return axiosHttp.get(`/scarcity`, params);
};

export const getMovementsByProductId = (_id: string): Promise<any> => {
  return axiosHttp.get(`/movements/${_id}`);
};

export interface MovementsPaginatedResponse {
  records: Movement[];
  total: number;
  pageSize: number;
  currentPage: number;
}

export const getMovements = (
  page = 1,
  limit = 18,
  filters?: any
): Promise<AxiosResponse<MovementsPaginatedResponse>> => {
  const p: any = { page, limit };
  if(filters) {
    const f:any = {};
    Object.keys(filters).filter((key) => !!filters[key]).forEach((key) => (f[key] =filters[key]));
    p['filters'] = f;
  }
  const params: AxiosRequestConfig = {
    params: p,
  };
  return axiosHttp.get(`/movements`, params);
};


export const setMovementByProductId = (
  productId: string,
  quantity: number,
  type: MovementTypes,
  description?: string
): Promise<any> => {
  return axiosHttp.post(`/movements/${productId}`, { quantity, type, description});
};
