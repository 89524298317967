import { useGlobalContext } from 'GlobalContex';
import { Col, Row, notification } from 'antd';
import { CloseIcon } from 'assets/icons';
import BigButton, { ButtonType } from 'components/BigButton/BigButton';
import CategoryRow from 'components/CategoryRow/CategoryRow';
import FooterBar from 'components/FooterBar/FooterBar';
import IconButton from 'components/IconButton/IconButton';
import { AlignedRight, PageContainer, Title } from 'components/StyledComponents';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCategories } from 'services/categories';
import { Category } from 'types/Category';
import styles from './Categories.module.scss';

interface CategoriesProps {}

const Categories: FC<CategoriesProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [api, contextHolder] = notification.useNotification();
  const { setIsLoading } = useGlobalContext();
  const [ categories, setCategories ] = useState<Category[]>([]);

  const getCategoriesHandler = async () => {
    setIsLoading(true);
    await getCategories()
    .then((result) => {
      setCategories(result.data);
    })
    .catch((error) => {
      api.error({
        message: 'Warning!',
        description: error.message,
      });
    })
    .finally(() => setIsLoading(false));

  };

  useEffect(() => {
    getCategoriesHandler();
  }, []);


  return (
    <div className={styles.Categories}>
      {contextHolder}
      <PageContainer>
        <Row gutter={[8, 16]} className={styles.topBlock}>
            <Col span={20}>
              <Title $dark={true}>
                Categorie
              </Title>
            </Col>
            <Col span={4}>
              <AlignedRight >
                <IconButton >
                  <CloseIcon style={{ height: "50px" }} onClick={() => {
                    let prevPath: string  = '/pages';
                    if(location.state && location.state.prevPath) {
                      prevPath =  (location.state.prevPath as string[]).pop() || '/pages';
                    } 
                    navigate(prevPath, {state : { prevPath: (location.state && [...location.state.prevPath]) || ['/pages'] }});
                  }} />
                </IconButton>
              </AlignedRight>
            </Col>
        </Row>

        <div className={styles.categoriesHeader}>
          <div className={styles.categoriesHeader_cell}>Nome</div>
        </div>
        <div className={styles.categoriesContainer}>
          {categories && categories.map((category) => <CategoryRow onClick={() => {
            navigate(`/pages/categories/${category._id}`, {state : { prevPath: (location.state && [...location.state.prevPath,  location.pathname]) || [location.pathname] }})
          }} key={category._id} category={category} />)}
        </div>
        <FooterBar>
            <BigButton
                onClick={() => {
                  navigate(`/pages/categories/new`, {state : { prevPath: (location.state && [...location.state.prevPath,  location.pathname]) || [location.pathname] }})
                }}
                label={'Nuova Categoria'}
                fullSize
                style={('tuftsBlue') as ButtonType}
              />
          </FooterBar>
      </PageContainer>
    </div>
  );
};

export default Categories;
