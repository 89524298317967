import { Category } from "types/Category";
import axiosHttp from "../utils/axios";

export const getCategories = (): Promise<any> => {
  return axiosHttp.get(`/categories`);
};

export const getCategoryById = (_id: string): Promise<any> => {
  return axiosHttp.get(`/categories/${_id}`);
};

export const insertCategory = (category: Category): Promise<any> => {
  return axiosHttp.post(`/categories`, {...category});
};

export const updateCategoryById = (id: string, category: Category): Promise<any> => {
  return axiosHttp.put(`/categories/${id}`, {...category});
};