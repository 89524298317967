import RowContainer from 'components/RowContainer/RowContainer';
import { FC } from 'react';
import { Category } from 'types/Category';
import styles from './CategoryRow.module.scss';

interface CategoryRowProps extends React.ButtonHTMLAttributes<HTMLDivElement> {
  category: Category;
}

const CategoryRow: FC<CategoryRowProps> = ({category, onClick}) => (
  <div className={styles.CategoryRow} onClick={onClick}>
    <RowContainer categoryColor={category.color}>
      <div className={styles.categoryName}>{category.name}</div>
    </RowContainer>
  </div>
);

export default CategoryRow;
