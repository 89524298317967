import { useGlobalContext } from 'GlobalContex';
import { Col, Pagination, PaginationProps, Row, notification } from 'antd';
import { CloseIcon } from 'assets/icons';
import cn from 'classnames';
import IconButton from 'components/IconButton/IconButton';
import MovementRow from 'components/MovementRow/MovementRow';
import { AlignedRight, PageContainer, TacoTabs, Title } from 'components/StyledComponents';
import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCategories } from 'services/categories';
import { getMovements } from 'services/products';
import { Category } from 'types/Category';
import { Movement } from 'types/Movement';
import styles from './Movements.module.scss';

const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
  if (type === 'prev') {
    return <a>{`< prev`}</a>;
  }
  if (type === 'next') {
    return <a>{`next >`}</a>;
  }
  return originalElement;
};

const Movements: FC = () => {
  const navigate = useNavigate();
  const { setIsLoading } = useGlobalContext();
  const [api, contextHolder] = notification.useNotification();
  const location= useLocation();

  const defaultLimit = 10;

  const [categories, setCategories] = useState<Category[]>([]);
  const [movements, setMovements] = useState<Movement[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [categoryId, setCategoryId] = useState<string>(localStorage.getItem('categoryId') as string);


  const getCategoriesHandler = async () => {
    setIsLoading(true);
    await getCategories().then((result) => {
      setCategories(result.data);
      !categoryId && categoryIdHandler(result.data[0]._id);
    })
    .catch((error) => {
      api.error({
        message: 'Warning!',
        description: error.message,
      });
    })
    .finally(() => setIsLoading(false));
  };

  const getMovementsHandler = async (page: number, limit: number, filters?: any) => {
    setIsLoading(true);
    await getMovements(page, limit, filters)
    .then((result) => {
      setTotal(result.data.total);
      setMovements(result.data.records);
    })
    .catch((error) => {
      api.error({
        message: 'Warning!',
        description: error.message,
      });
    })
    .finally(() => setIsLoading(false));

  };

  const categoryIdHandler = (categoryId: string) => {
    localStorage.setItem('categoryId', categoryId);
    setCategoryId(categoryId);
  }

  useEffect(()=> {
    getCategoriesHandler();
  }, []);

  useEffect(()=> {
    categoryId && getMovementsHandler(1, defaultLimit, {category: categoryId});
  }, [categoryId]);

  const tabs = useMemo(
    () => categories.map(category => { 
      return {
        label: (
          <div>
            {category.name}
          </div>
        ),
        key: category._id,
        children: (<></>),
      };
      }
  ),
    [categories]
  );

  return (
    <div className={styles.Movements}>
      {contextHolder}
      <PageContainer>
        <Row gutter={[8, 16]} align={'middle'} className={styles.topBlock}>
          <Col span={20}>
            <Title $dark={true}>
              Movimenti
            </Title>
          </Col>
          <Col span={4}>
            <AlignedRight >
              <IconButton >
                <CloseIcon style={{ height: "50px" }} onClick={() => {
                  let prevPath: string  = '/pages';
                  if(location.state && location.state.prevPath) {
                    prevPath =  (location.state.prevPath as string[]).pop() || '/pages';
                  } 
                  navigate(prevPath, {state : { prevPath: (location.state && [...location.state.prevPath]) || ['/pages'] }});
                }} />
              </IconButton>
            </AlignedRight>
          </Col>
        </Row>
        <TacoTabs className={styles.tabs} type="card" items={tabs} activeKey={categoryId} onTabClick={(key) => {
          categoryIdHandler(key)
        }} />
        <div className={styles.movementsHeader}>
          <div className={styles.movementsHeader_cell}>PRODOTTO</div>
          <div className={styles.movementsHeader_cell}>data e ora</div>
          <div className={cn(styles.movementsHeader_cell, styles.movementsHeader_notes)}>note</div>
          <div className={styles.movementsHeader_cell}>Carico/Scarico</div>
        </div>
        <div className={styles.movementsContainer}>
          {(movements && movements.length && movements.map((movement) => <MovementRow onClick={() => {
            if(movement.product) {
              navigate(`/pages/products/${movement.product._id}`, {state : { prevPath: (location.state && [...location.state.prevPath,  location.pathname]) || [location.pathname] }});
            }
          }} key={movement._id} movement={movement} />)) || <div className={styles.noMovements}>Nessun movimento presente.</div>}
        </div>
        <div className={styles.paginationContainer}>
          <Pagination
            onChange={(page, limit) => getMovementsHandler(page, limit, {category: categoryId})}
            total={total}
            defaultPageSize={defaultLimit}
            defaultCurrent={1}
            itemRender={itemRender}
          />
        </div>
      </PageContainer>
    </div>
  );
}

export default Movements;
