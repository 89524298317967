import { Category } from "./Category";
import { Product } from "./Product";

export interface Movement {
  _id: number;
  productName: string;
  productId: number;
  productType: number;
  createdAt: string;
  description: string;
  quantity: number;
  product?: Product;
  category: Category;
  type: MovementTypes;
}

export enum MovementTypes {
    LOAD = 'LOAD',
    UNLOAD = 'UNLOAD',
}
