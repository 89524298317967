import { Table, TablePaginationConfig, Tooltip } from "antd";
import QuantityTag from "components/QuantityTag/QuantityTag";
import { format } from "date-fns";
import { AlignType } from "rc-table/lib/interface";
import { FC, useMemo } from "react";
import { Movement } from "../../types/Movement";
import MovementTag from "../MovementTag/MovementTag";
import { Elipsis, TableHighlitedText } from "../StyledComponents";
import styles from "./MovementsTable.module.scss";

interface MovementsTableProps {
  records: Movement[];
  showProductName?: boolean;
  showDescription?: boolean;
  showPagination?: false | TablePaginationConfig | undefined;
  showQuantity?: boolean;
  small?: boolean;
}

const MovementsTable: FC<MovementsTableProps> = ({
  records,
  showProductName = true,
  showDescription = false,
  showQuantity = false,
  showPagination = false,
  small = false,
}) => {
  const columns = useMemo(() => {
    let cols = [
      {
        title: "Prodotto",
        key: 'productName',
        render: (movement: Movement) => (
          <TableHighlitedText>{(!!movement.product && movement.product.name) || 'sconosciuto'}</TableHighlitedText>
        ),
      },
      {
        title: "Data",
        key: "createdAt",
        render: (movement: Movement) =>
          movement.createdAt
            ? format(movement.createdAt, "dd/MM/yyyy hh:mm")
            : "",
      },
      {
        title: "Note",
        key: "description",
        render: (movement: Movement) => {
          return (

            <Tooltip trigger={['click']} title={movement.description}>
               <Elipsis style={{'width': '250px'}}>{movement.description}</Elipsis> 
            </Tooltip>
          );
        },

      },
      {
        title: "Variazione",
        key: "variation",
        align: "right" as AlignType,
        render: (movement: Movement) => {
          return (
            <MovementTag quantity={movement.quantity} type={movement.type}  />
          );
        },
      },
      {
        title: "Quantità",
        key: "quantity",
        align: "right" as AlignType,
        render: (movement: Movement) => {
          return (
            <QuantityTag quantity={movement.quantity}  />
          );
        },
      },
    ];

    return cols.filter((col) => {
      if (col.key === "productName") return showProductName;
      if (col.key === "description") return showDescription;
      if (col.key === "quantity") return showQuantity;
      return true;
    });
  }, [showProductName, showDescription, showQuantity]);

  return (
    <div className={styles.MovementsTable}>
      <Table
        dataSource={records}
        columns={columns}
        showHeader={false}
        pagination={showPagination}
        rowKey="_id"
        className={(small && "small12") || ""}
      />
    </div>
  );
};

export default MovementsTable;
