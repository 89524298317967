import React, { FC, createContext, useMemo, useState } from "react";

interface IGlobalContext {
  isLoading: boolean;
  userName: string;
  userId: string;
  setIsLoading: (isLoading: boolean) => void;
  setUserName: (userName: string) => void;
  setUserId: (userId: string) => void;
}

export const GlobalContext = createContext<IGlobalContext | null>(null);

export const GlobalContextProvider: FC<any> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>(localStorage.getItem('taco_username') || '');
  const [userId, setUserId] = useState<string>(localStorage.getItem('taco_user_id') || '');


  const exposedData = useMemo(() => {
    return {
      isLoading,
      userName,
      userId,
      setIsLoading,
      setUserName,
      setUserId,
    };
  }, [isLoading, userName, userId]);
  return (
    <GlobalContext.Provider value={exposedData}>
      {children}
    </GlobalContext.Provider>
  );
};

export function useGlobalContext() {
  const context = React.useContext(GlobalContext);

  if (!context) {
    throw new Error(
      "useGlobalContext must be used within the scope of GlobalContextProvider"
    );
  }

  return context;
}
