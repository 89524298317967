import { useGlobalContext } from 'GlobalContex';
import { Col, Form, Input, Row, notification } from 'antd';
import { CloseIcon } from 'assets/icons';
import cn from 'classnames';
import BigButton, { ButtonType } from 'components/BigButton/BigButton';
import FooterBar from 'components/FooterBar/FooterBar';
import IconButton from 'components/IconButton/IconButton';
import ScarcityRow from 'components/ScarcityRow/ScarcityRow';
import { AlignedRight, PageContainer, Title } from 'components/StyledComponents';
import { FC, useRef, useState } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { searchProducts } from 'services/products';
import { Product } from 'types/Product';
import styles from './Search.module.scss';

const Search: FC = () => {
    const { setIsLoading } = useGlobalContext();
    const navigate = useNavigate();
    const location = useLocation();
    const [term, setTerm] = useState<string>('');
    const [products, setProducts] = useState<Product[]>([]);
    const [searchDone, setSearchDone] = useState<boolean>(false);
    const inputRef = useRef<any>(null);

    const [api, contextHolder] = notification.useNotification();  
    const searchProductsHandler = async (term: string) => {
      setIsLoading(true);
      setTerm(term);
      await searchProducts(term)
      .then((result) => {
        setProducts(result.data);
        setSearchDone(true);
        !!inputRef && !!inputRef.current && inputRef.current.select();
      })
      .catch((error) => {
        api.error({
          message: 'Warning!',
          description: error.message,
        });
      })
      .finally(() => setIsLoading(false));
    };

    const onCloseHandler = () => {
      let prevPath: string  = '/pages';
      if(location.state && location.state.prevPath) {
        prevPath =  (location.state.prevPath as string[]).pop() || '/pages';
      } 
      navigate(prevPath, {state : { prevPath: (location.state && [...location.state.prevPath]) || ['/pages'] }});
    }

    const addProduct = (e: any) => {
      e.preventDefault(); 
      navigate({
                pathname: '/pages/products/new', 
                search: createSearchParams({
                  term: term
                }).toString(),
              },   
              {state : { prevPath: (location.state && [...location.state.prevPath,  location.pathname]) || [location.pathname] }}
            );
          }

    const handleFocus = (event: any) => event.target.select();
    
  return (
    <div className={styles.Search}>
      {contextHolder}
      <PageContainer>
        <Row gutter={[8, 16]} align={'middle'} className={styles.topBlock}>
          <Col xs={6} md={6}>
            <Title $dark={true}>
              Cerca
            </Title>
          </Col>
          <Col xs={12} md={12}>
            <Form 
              name="login"
              autoComplete="off"
              onFinish={(values) => {
                searchProductsHandler(values.term);
              }}
              >
              <Form.Item name="term" rules={[{ required: true, message: 'Il campo è obbligatorio !' }]}>
                <Input ref={inputRef} onFocus={handleFocus} autoFocus type="string" />
                
              </Form.Item>
              <div className={styles.inputFooter}>
                  {searchDone && <div className={cn(styles.inputProducts, products.length > 0 ? styles.inputProductsFound : styles.inputProductsNotFound)}>
                    {(products.length > 0 && <><span>Trovati</span><span>{products.length}</span></>)  || <span>Nessun Prodotto Trovato</span>}
                  </div>}
                  {searchDone && products.length > 0 && <div className={styles.inputAddNew}>
                    <span>Oppure <a href='#' onClick={addProduct}>Inserisci nuovo prodotto</a></span>
                  </div>}
                </div>
          </Form>
          </Col>
          <Col xs={6} md={6}>
            <AlignedRight >
              <IconButton >
                <CloseIcon style={{ height: "50px" }} onClick={onCloseHandler} />
              </IconButton>
            </AlignedRight>
          </Col>
          
        </Row>
        <div className={styles.productsContainer}>
          {products && products.map((product) => <ScarcityRow onClick={() => {

            navigate(`/pages/products/${product._id}`, {state : { prevPath: (location.state && [...location.state.prevPath,  location.pathname]) || [location.pathname] }})
          }} key={product._id} product={product} />)}
        </div>
        

        {searchDone && products.length === 0 && <FooterBar>
          <BigButton
            label="annulla"
            fullSize
            style={"silverChalice" as ButtonType}
            onClick={onCloseHandler}
            />
          <BigButton
            label="aggiungi prodotto"
            fullSize
            style={"silverChalice" as ButtonType}
            onClick={addProduct}
            />
        </FooterBar>}
      </PageContainer>
    </div>
  );
};
export default Search;
