import { Card, Tabs } from "antd";
import styled, { css } from "styled-components";
import { mediaQueries } from "styles/mediaQueries";

export const Title = styled.h1.attrs<{ $dark?: boolean }>((props) => ({
  $dark: props.$dark,
}))`
  text-transform: uppercase;
  font-size: 26px;
  line-height: 25px;
  margin-bottom: 18px;
  font-weight: 600;
  font-family: var(--font-family-2);
  ${(props) =>
    (props.$dark &&
      css`
        color: var(--font-color);
      `) ||
    css`
      color: var(--font-color-light);
    `}
`;

export const SectionTitle = styled.h3`
  text-transform: uppercase;
  font-size: 20px;
  line-height: 20px;
  font-family: var(--font-family-2);
  color: var(--font-color);
  margin-bottom: 15px;
  font-weight: 400;
`;

export const PageContainer = styled.div`
  ${mediaQueries("xs")`
    padding: 80px 16px 250px 16px;
  `};
  ${mediaQueries("md")`
    padding: 110px 50px 250px 50px;
  `};
`;

export const AlignedRight = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;

export const ImportantText = styled.div`
  font-family: var(--font-family-2);
  text-transform: uppercase;
  color: var(--font-color-light);
  font-size: 50px;
  line-height: 50px;
  font-weight: 600;
`;

export const TableHighlitedText = styled.div`
  font-family: var(--font-family-3);
  color: var(--font-color-light);
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
`;

export const Elipsis = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
`;

export const FormLabel = styled.label`
  font-family: var(--font-family-2);
  color: var(--font-color);
  text-transform: uppercase;
  display: inline-block;
  font-size: 16px;
  line-height: 18px;
  font-weight: 900;
  padding-left: 24px;
  padding-bottom: 8px;
`;

export const TacoCard = styled(Card).attrs<{ $color: string }>((props) => ({
  $color: props.$color,
}))`
  .ant-card-head {
    color: #ffffff;
    text-transform: uppercase;
    background-color: ${(props) => props.$color};
  }
`;

export const TacoTabs = styled(Tabs)`
  .ant-tabs-tab {
    border-bottom: 2px solid #3d444e !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    border-bottom: 2px solid var(--font-color) !important;
  }
`;
