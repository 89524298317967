import { Col, ConfigProvider, Form, Input, Row } from 'antd';
import BigButton, { ButtonType } from 'components/BigButton/BigButton';
import { FormLabel } from 'components/StyledComponents';
import { FC } from 'react';
import { MovementTypes } from 'types/Movement';
import styles from './QuantityForm.module.scss';

interface QuantityFormProps {
  type: MovementTypes;
  onCancel: () => void
  onGo: (quantity: number, type: MovementTypes, description?: string) => void
}

interface MovementParameters {
  quantity: number;
  type: MovementTypes;
  description?: string;
}
const QuantityForm: FC<QuantityFormProps> = ({type, onCancel, onGo}) => {

  const rules: any = (type === MovementTypes.UNLOAD) ? { required: true, message: 'Per lo SCARICO il campo NOTE è obbligatorio !' } : null;

  const onGoHandler = ({quantity, description}: MovementParameters) => {
    return onGo(quantity, type, description);
  }

  return (
  <div className={styles.QuantityForm}>
    <ConfigProvider
      theme={{
        components: {
          Input: {
            colorBgContainer: "#363A40",
            colorBorder: "#4C535D",
          }
        }
      }}
    >
      <Form 
      name="login"
      autoComplete="off"
      onFinish={onGoHandler}>
        <Row gutter={[30, 70]}>
          <Col span={12}>
            <FormLabel>quantità</FormLabel>
            <Form.Item name="quantity" rules={[
                { required: true, message: 'Il campo QUANTITÀ è obbligatorio !' }, 
                { pattern: new RegExp(/^(?=.*\d)\d*(?:\.\d\d)?$/), message: "Per il campo QUANTITÀ sono ammessi solo numeri es:10 o 10.00"},
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject();
                    }
                    if (parseFloat(value) < 1) {
                      return Promise.reject('Il valore minimo per il campo QUANTITÀ è 1');
                    }
                    return Promise.resolve();
                  },
                })
              ]}>
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col span={12}>
              <FormLabel>note</FormLabel>
            <Form.Item name="description" rules={[rules]} >
              <Input type="string"/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <BigButton
              label="annulla"
              fullSize
              style={"silverChalice" as ButtonType}
              onClick={(e) => {
                e.preventDefault();
                onCancel();
              }}
            />
          </Col>
          <Col span={12}>
            <BigButton
              type='submit'
              label={type === MovementTypes.LOAD ? 'carica +' : 'scarico -'}
              fullSize
              style={(type === MovementTypes.LOAD ? 'ufoGreen' : 'sunsetOrange') as ButtonType}
            />
          </Col>
        </Row>
      </Form>
    </ConfigProvider>
  </div>
);
}

export default QuantityForm;
